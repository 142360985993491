import { PageProps, graphql } from "gatsby";
import React, { useMemo } from "react";
import ShopProductsContainer, {
  ProductType,
} from "../components/Shop/shop-products-container";
import { brandsToExclude, categoriesToExclude } from "../queries/toExclude";

import { FetchAllCategories } from "../components/hooks/fetchAllCategories";
import Layout from "../components/Base/Layout";
import { Location } from "@reach/router";
import { SEO } from "../components/Base/Seo";
import { navigate } from "gatsby";
import useAuth from "../components/useAuth";
import { useState } from "react";

interface ProductTag {
  [key: string]: { name: string; id: string };
}

const Shop: React.FC<Shop> = ({ data, pageContext, location }: any) => {
  const {
    allWpSimpleProduct: { nodes: allSimpleNodes },
    allWpVariableProduct: { nodes: allVariableNodes },
    wpProductCategory: {
      description: any,
      catDescriptionTop: { descriptionTop },
    },
  } = data;

  const allNodes = [...allSimpleNodes, ...allVariableNodes];

  const params = new URLSearchParams(location.search);
  const pageParam = params.get("page");

  const categories = FetchAllCategories();

  const { loggedIn, isUnlicensedUser, isDefaultCustomer } = useAuth();
  const categorySlug =
    allNodes.length > 0 ? allNodes[0].productCategories.cat[0].slug : "";

  const filteredProducts = React.useMemo(() => {
    let allFilteredProducts = allNodes;

    if (isUnlicensedUser || !loggedIn || isDefaultCustomer) {
      allFilteredProducts = allNodes.filter((item: any) => {
        return item.productTags?.nodes.every(
          (tag: any) =>
            tag.name !== "Unlicensed" && tag.name !== "Pharmaceuticals"
        );
      });
    }
    return allFilteredProducts;
  }, [isUnlicensedUser, isDefaultCustomer, allNodes, loggedIn]);

  const relatedProductCategoryName = "Related Product";
  const filteredCategories = React.useMemo(() => {
    if (isUnlicensedUser || !loggedIn || isDefaultCustomer) {
      return categories.filter((item: any) => {
        return (
          !categoriesToExclude.includes(item.name) &&
          item.name !== relatedProductCategoryName &&
          item.name !== "Recommended Products" &&
          item.name !== "Bestselling Products"
        );
      });
    }

    return categories?.filter(
      (item: any) =>
        item.name !== relatedProductCategoryName &&
        item.name !== "Recommended Products" &&
        item.name !== "Bestselling Products"
    );
  }, [isUnlicensedUser, isDefaultCustomer, categories, categoriesToExclude]);

  const nodes = React.useMemo(() => {
    let allFilteredNodes = allNodes;

    if (isDefaultCustomer || isUnlicensedUser || !loggedIn) {
      allFilteredNodes = allNodes?.filter((item: any) => {
        return item.productTags?.nodes.every(
          (tag: any) => tag.name !== "Pharmaceuticals"
        );
      });
    }

    return allFilteredNodes;
  }, [isDefaultCustomer, isUnlicensedUser, allNodes, loggedIn]);

  const filteredBrands = React.useMemo(() => {
    let allBrands = allNodes;
    if (isUnlicensedUser || !loggedIn || isDefaultCustomer) {
      allBrands = allNodes.filter((item: any) => {
        return item.productTags?.nodes.every(
          (tag: any) =>
            tag.name !== "Unlicensed" && tag.name !== "Pharmaceuticals"
        );
      });
      return allBrands.filter((item: any) => {
        return !brandsToExclude.includes(item.name);
      });
    }

    return allNodes.filter((item: any) => {
      return (
        item.name !== "Unlicensed" &&
        item.name !== "Medical Devices" &&
        item.name !== "Pharmaceuticals"
      );
    });
  }, [isUnlicensedUser, loggedIn, isDefaultCustomer, allNodes]);

  const filteredBrandsIDS = useMemo(() => {
    const uniqueProductTags: any[] = [];
    const uniqueProductTagsById: ProductTag = {};

    filteredBrands.forEach((product: any) => {
      product?.productTags?.nodes.forEach((tag: any) => {
        if (!uniqueProductTagsById[tag.id]) {
          uniqueProductTagsById[tag.id] = tag;
          uniqueProductTags.push(tag);
        }
      });
    });

    return uniqueProductTags;
  }, [allNodes]);

  const allProductsPerPage = 20;
  const [productsPerPage, setProductsPerPage] = useState(allProductsPerPage);
  const [currentPage, setCurrentPage] = useState(Number(pageParam) || 1);
  const [input, setInput] = useState(Number(pageParam) || 1);

  const [filtered, setFiltered] = useState(
    nodes
      ? nodes.sort((a: any, b: any) => {
        return a.name.localeCompare(b.name);
      })
      : []
  );

  const numberOfPages = React.useMemo(() => {
    return Math.ceil(
      (filtered.length > 0 ? filtered.length : nodes.length) / productsPerPage
    );
  }, [filtered, nodes]);

  React.useEffect(() => {
    if ((pageParam && !Number(pageParam)) || Number(pageParam) == 1) {
      navigate(`/category/${pageContext.slug.toLowerCase()}`);
    }
    if (Number(pageParam) > numberOfPages) {
      setCurrentPage(numberOfPages);
      navigate(
        `/category/${pageContext.slug.toLowerCase()}?page=${numberOfPages}`
      );
      setInput(numberOfPages);
    }
    if (Number(pageParam) < 1) {
      setCurrentPage(1);
      navigate(`/category/${pageContext.slug.toLowerCase()}`);
      setInput(1);
    }
  }, [pageParam, numberOfPages]);

  const pageInputRef = React.useRef<HTMLInputElement | null>(null);

  const handlePageInputSubmit = React.useCallback(
    (event: any) => {
      if (event.key === "Enter" && input && input !== currentPage) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        const parsedInput = Number(input);
        pageInputRef?.current?.blur();
        if (parsedInput <= 1) {
          setCurrentPage(1);
          setInput(1);
          navigate(`/category/${pageContext.slug.toLowerCase()}`);
        } else if (parsedInput > numberOfPages) {
          setCurrentPage(numberOfPages);
          setInput(numberOfPages);
          navigate(
            `/category/${pageContext.slug.toLowerCase()}?page=${numberOfPages}`
          );
        } else {
          setCurrentPage(parsedInput);
          navigate(
            `/category/${pageContext.slug.toLowerCase()}?page=${parsedInput}`
          );
        }
      }
    },
    [input, currentPage]
  );

  React.useEffect(() => {
    if (pageInputRef.current) {
      pageInputRef.current.addEventListener("keypress", handlePageInputSubmit);
    }

    return () => {
      if (pageInputRef.current) {
        pageInputRef.current.removeEventListener(
          "keypress",
          handlePageInputSubmit
        );
      }
    };
  }, [pageInputRef, handlePageInputSubmit]);

  //   return filtered.length > 0
  //     ? filtered.slice(indexOfFirstProduct, indexOfLastProduct)
  //     : nodes.slice(indexOfFirstProduct, indexOfLastProduct);
  // }, [filtered, nodes, currentPage]);

  // // GOOGLE ANALYTICS - CURRENT LIST OF VISIBLE ITEMS
  // useEffect(() => {
  //   // @ts-ignore
  //   window.dataLayer = window.dataLayer || [];
  //   // @ts-ignore
  //   window.dataLayer.push({
  //     event: "view_item_list",
  //     ecommerce: {
  //       items: visibleProducts?.map((item: any, index: any) => ({
  //         item_name: item.name,
  //         item_id: item.databaseId,
  //         price: item.price
  //           ? parseFloat(item.price.replace("$", ""))
  //           : undefined,
  //         item_brand: item.brand,
  //         item_category: item.productCategories,
  //         item_list_name: "Shop page",
  //         item_list_id: "shop_page",
  //         index: index + 1,
  //         quantity: 1,
  //       })),
  //     },
  //   });
  // }, [visibleProducts]);

  console.log("filteredProducts", filteredProducts);

  return (
    <Layout>
      <ShopProductsContainer
        // @ts-ignore
        title={pageContext.name}
        location={location}
        category={pageContext.slug}
        categories={filteredCategories}
        products={filteredProducts}
        brands={filteredBrandsIDS}
        isCategoryPage
        baseUrl={categorySlug}
        pageContext={pageContext}
        description={data?.wpProductCategory?.catDescriptionTop?.descriptionTop}
        categoryDescription={data?.wpProductCategory?.description}
      />
    </Layout>
  );
};

type Shop = PageProps<ShopProps>;

type ShopProps = {
  data: {
    allWpSimpleProduct: {
      totalCount: number;
      nodes: Array<{
        name: string;
        id: string;
        slug: string;
        price: string;
        databaseId: number;
        image: { sourceUrl?: string; altText?: string };
        productCategories: { cat: [{ name: string; id: string }] };
      }>;
      pageInfo: {
        currentPage: number;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        itemCount: number;
        perPage: number;
        totalCount: number;
        pageCount: number;
      };
    };
  };
  pageContext: any;
  location: Location;
};

type HeadProps = {
  wpProductCategory: {
    seo?: {
      title?: string;
      metaDesc?: string;
    };
  };
};

export const Head: React.FC<HeadProps> = ({ data }: any) => {
  const wpProductCategory: {
    seo: { title: any; metaDesc: any };
  } = data;
  return (
    <SEO
      title={data.wpProductCategory.seo?.title}
      description={data.wpProductCategory.seo?.metaDesc}
    />
  );
};

export const query = graphql`
  query AllProductsShop($slug: String!) {
  allWpSimpleProduct(
    filter: {productCategories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}
  ) {
    totalCount
    nodes {
      name
      id
      slug
      databaseId
      price
      regularPrice
      onSale
      salePrice
      promotions {
        onPromotion
        additionalTag
      }
      productTags {
        nodes {
          name
          slug
          id
          count
        }
      }
      productCategories {
        cat: nodes {
          name
          id
          slug
          databaseId
          seo {
            title
            metaDesc
          }
        }
      }
      image {
        sourceUrl
        altText
      }
    }
    pageInfo {
      currentPage
      hasNextPage
      itemCount
      totalCount
      perPage
      hasPreviousPage
      pageCount
    }

  }
    allWpVariableProduct(
			filter: { productCategories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
		) {
			totalCount
			nodes {
				name
				id
				slug
				databaseId
				price
				image {
					sourceUrl
					altText
				}
				productTags {
					nodes {
						name
						slug
					}
				}
				productTags {
					tag: nodes {
						name
						id
						slug
					}
				}

        ... on WpVariableProduct {
					id
					name
					regularPrice
					salePrice
					onSale
				}
			}
    
			pageInfo {
				currentPage
				hasNextPage
				itemCount
				totalCount
				perPage
				hasPreviousPage
				pageCount
			}
		}
  wpProductCategory(slug: {eq: $slug}) {
    description
    catDescriptionTop {
      descriptionTop
    }
    seo {
      title
      metaDesc
    }
  }
}
`;

export default Shop;
